import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Grid, Row, Typography } from 'antd';
import { PageProps } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import Container from '@/components/Container';
import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import SocialIcons from '@/components/SocialIcons';
import { useSiteMetadata } from '@/hooks/useSiteMetadata';
import OrganizationSchema from '@/schema/OrganizationSchema';
import { getRandomInt } from '@/utils';
import { getCurrentBreakpoint } from '@/utils/mappings';

const ContactPage: React.FC<PageProps> = ({ uri }) => {
  const screens = Grid.useBreakpoint();
  const currentBreakpoint = getCurrentBreakpoint(screens);
  const { title: brandName, siteUrl, logoUrl } = useSiteMetadata();
  const businessLdJson = JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'HealthAndBeautyBusiness',
    name: brandName,
    brand: brandName,
    image: `${siteUrl}${logoUrl}`,
    url: `${siteUrl}${uri}`,
    priceRange: '$',
    telephone: '+7 (846) 922-88-33',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: `4.${getRandomInt(80, 99)}`,
      reviewCount: Math.round(
        (Date.now() - Number(new Date(2021, 0))) / 86400000 / 7 +
          getRandomInt(1, 20)
      ),
    },
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+7 (846) 922-88-33',
        email: 'mail@rasept.com',
        contactType: 'Информация',
      },
    ],
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'пр. Кирова 24, стр. 2',
      addressLocality: 'Самара',
      addressRegion: 'Самарская обл.',
      postalCode: '443022',
      addressCountry: 'RU',
    },
  });

  return (
    <Layout>
      <SEO
        title="Контактная информация"
        description="Узнайте, как связаться с представителями Rasept и получить интересующую вас информацию и многое другое."
        uri={uri}
        image={null}
      />
      <OrganizationSchema />
      <Helmet>
        <script type="application/ld+json">{businessLdJson}</script>
      </Helmet>
      <Container>
        <Typography.Title className="text-center my-8" level={1}>
          Контактная информация{' '}
        </Typography.Title>
        <Row
          align="middle"
          justify="center"
          style={{ backgroundColor: '#fff' }}
        >
          <Col
            flex={['xs', 'sm'].includes(currentBreakpoint) ? 'auto' : '360px'}
            className="px-8 py-2"
          >
            <Divider>Пишите и звоните</Divider>
            <div className="text-center">
              <Typography.Paragraph
                copyable={{
                  text: 'mail@rasept.com',
                  tooltips: ['Скопировать', 'Скопировано!'],
                }}
              >
                <span className="text-xl">
                  <MailOutlined className="mr-3" style={{ color: 'gray' }} />
                  mail@rasept.com
                </span>
              </Typography.Paragraph>
              <Typography.Paragraph className="text-xl">
                <PhoneOutlined className="mr-2" style={{ color: 'gray' }} />
                <a style={{ color: 'inherit' }} href="tel:+78469228833">
                  <abbr>+7 (846) 922-88-33</abbr>
                </a>
              </Typography.Paragraph>
            </div>
            <Divider className="mt-16">Мессенджеры</Divider>
            <Button
              icon={
                <img
                  className="anticon"
                  src="/icons/social/vk.svg"
                  alt="Написать нам «ВКонтакте»"
                  style={{ height: 21, margin: '0 0 -7px 0' }}
                />
              }
              block
              className="text-left"
              size="large"
              href="https://vk.com/im?sel=-137607669"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              Написать нам «ВКонтакте»
            </Button>
            <Button
              icon={
                <img
                  className="anticon"
                  src="/icons/social/messenger.svg"
                  alt="Написать нам в «Messenger»"
                  style={{ height: 21, margin: '0 0 -7px 0' }}
                />
              }
              block
              className="text-left mt-2"
              size="large"
              href="https://m.me/RaseptCom"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              Написать нам в «Messenger»
            </Button>
            <Divider className="mt-16">Соцсети</Divider>
            <SocialIcons />
          </Col>
          <Col flex="auto">
            <iframe
              title="Яндекс.Карты"
              src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=1863090149"
              width="100%"
              height="500"
              frameBorder="0"
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default ContactPage;
